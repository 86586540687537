import React from "react"
import { Link } from "gatsby"
import ScrollAnimation from "react-animate-on-scroll"
import { StaticImage } from "gatsby-plugin-image"

import { BsCardChecklist } from "react-icons/bs"
import { CgFileRemove } from "react-icons/cg"
import { FaNetworkWired } from "react-icons/fa"
import { IoIosPeople } from "react-icons/io"
import { RiInformationLine } from "react-icons/ri"

import loadable from "@loadable/component"
import pMinDelay from "p-min-delay"
import peaksuite from "../images/new-images/peaksuite-logo.png"

const PartnerSwiperSection = loadable(() =>
  pMinDelay(import("./peaksuite-sections/PartnerSwiperSection"), 500)
)
const PeaksuiteToolsLogos = loadable(() =>
  pMinDelay(import("./peaksuite-sections/PeaksuiteToolsLogos"), 500)
)
const PeaksuiteToolsDesc = loadable(() =>
  pMinDelay(import("./peaksuite-sections/PeaksuiteToolsDesc"), 500)
)

const PeaksuiteSections = () => (
  <>
    <PartnerSwiperSection />
    <div className="pb-0">
      <section className="py-md-2 pt-1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center text-left-mobile ">
              <h2 className="nospace-titlehead">
                The Average Employee Loses 69 Hours a Year on Unnecessary Manual
                Tasks. peakSUITE Gets Those Hours Back.
              </h2>
            </div>
          </div>
          <div className="row flex-lg-row flex-column-reverse mt-lg-5 mt-md-5 mt-0">
            <div className="col-lg-6 col-md-12 mt-lg-0 mt-md-0 mt-5">
              <p>
                peakSUITE is a cloud-based business management platform designed
                to automate your business processes even faster. Eliminate
                repetitive data entry tasks. Get actionable insights. Optimize
                productivity across your entire enterprise. All from a single,
                accessible portal.
              </p>
              <p>
                Our peakSUITE service line provides flexible, unified operations
                management tools with a rich list of features built-in. Increase
                operational efficiency and agility while boosting your bottom
                line with peakSUITE’s range of turnkey solutions. Best of all,
                tiered product lines give you the most essential features for
                your business within your budget.
              </p>
            </div>
            <div className="col-lg-6 col-md-12 mt-lg-0 mt-md-0 mt-5 align-self-center">
              <ScrollAnimation
                animateIn="fadeInUp"
                initiallyVisible={false}
                animateOnce={true}
                delay={100}
              >
                <Link aria-label="link" to="/peaksuite/">
                  <img
                    placeholder="blurred"
                    className="img-fluid w-100"
                    src={peaksuite}
                    alt="peakSuite logo"
                    layout="constrained"
                    loading="lazy"
                  />
                </Link>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </section>
      <PeaksuiteToolsLogos />
      <PeaksuiteToolsDesc />
      <section
        className="bg-indigo"
        style={{
          paddingTop: "5rem",
          paddingBottom: "5rem",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-10 mx-auto text-center text-left-mobile">
              <div>
                <h2 className="text-white">
                  peakSUITE is Your Accessible Portal for Easier, More Efficient
                  Operations
                </h2>
                <p className="text-white">
                  Whether you need to manage a single department or streamline
                  company-wide systems, you'll get what you need to run your
                  business with ease.
                </p>
                <Link
                  className="speak-to-btnx btn btn-primary btn-arrow mt-4"
                  to="/contact/"
                >
                  Speak to a Technology Consultant
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="peaksuite-glance"
        style={{ marginBottom: "5rem", marginTop: "5rem" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2>peakSUITE at a Glance</h2>
            </div>
            <div className="col-lg-4 col-md-6 mt-5 text-center">
              <CgFileRemove />
              <h3 className="my-4">
                Eliminate
                <br />
                Tedious Data Entry
              </h3>
              <p>Automate tasks to reduce time-wasting bottlenecks.</p>
            </div>
            <div className="col-lg-4 col-md-6 mt-5 text-center">
              <IoIosPeople />
              <h3 className="my-4">
                Connect
                <br />
                Employees
              </h3>
              <p>
                Reduce conflicts, miscommunications, and unnecessary errors with
                an accessible communication platform.
              </p>
            </div>
            <div className="col-lg-4 col-md-6 mt-5 text-center">
              <RiInformationLine />
              <h3 className="my-4">
                Keep
                <br />
                Everyone Informed
              </h3>
              <p>
                Provide transparency from inception to launch and at every stage
                in between.
              </p>
            </div>
            <div className="col-lg-4 col-md-6 mt-5 text-center ml-lg-auto ml-md-auto ml-0">
              <FaNetworkWired />
              <h3 className="my-4">
                Aggregate
                <br />
                Company-Wide Data
              </h3>
              <p>
                Empower leadership to make informed decisions with coherent
                reporting.
              </p>
            </div>
            <div className="col-lg-4 col-md-6 mt-5 text-center mr-lg-auto mr-md-auto mr-0 mx-lg-0 mx-md-auto mx-auto">
              <BsCardChecklist />
              <h3 className="my-4">
                Turnkey
                <br />
                Solutions
              </h3>
              <p>
                Integrate new systems without resource-intensive onboarding or
                extensive training.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/*<section className="peaksuite-deploying">
            <div className="container">
              <div className="row h-100">
                <div className="col-lg-6 col-md-12">
                  <h2>
                    Deploying Automation in Your Office Could Generate 70% - 80%
                    ROI in the First Year
                  </h2>
                  <p className="mt-4">
                    And more than 150% ROI in the years after.
                  </p>
                  <p>
                    peakSUITE gives every owner of a growing business what they
                    really want. The time and resources to invest in continued
                    success. NOT expensive technology and programming efforts that
                    sprawl endlessly, eating through budgets while exposing
                    companies to increased risk.
                  </p>
                  <p>
                    Avoid the high cost of hiring programmers and technology specialists
                    to build and manage new systems.
                  </p>
                  <p>
                    <i>Systems that may never reach market...</i>
                  </p>
                  <p>
                    Choose peakSUITE and get custom solutions tailored to your
                    immediate business needs.
                  </p>
                  <p>
                    Whether you’re a small business or an enterprise, peakSUITE
                    Applications give you the agility you need to remain
                    competitive in even the most unpredictable markets.
                  </p>
                </div>
                <div className="col-lg-6 col-md-12 mt-lg-0 mt-md-5 mt-5 h-auto">
                  <StaticImage
                    placeholder="blurred"
                    className="h-100"
                    imgClassName="rounded peaksuite-deploying-img"
                    src="../../images/new-images/GolfImg.jpg"
                    alt="Deploying Automation"
                    layout="constrained"
                  />
                  {/* <div
                    style={{
                      background: `url(${GolfImg})`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "30% center",
                      height: "100%",
                    }}
                    className="rounded peaksuite-deploying-img"
                  /> */}
      {/* <LazyLoadImage
                    placeholder="blurred"
                    className="rounded img-fluid"
                    src={GolfImg}
                    alt="Golf Img"
                  // /> */}
      {/*   </div>
              </div>
            </div>
          </section> */}
      {/* <section
            className="bg-indigo"
            style={{
              // background: "#231f20",
              padding: "5rem 0",
              paddingTop: "5rem",
              paddingBottom: "5rem",
              marginTop: "5rem",
              marginBottom: "5rem",
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12 text-center">
                  <h2 className="text-white">Think Productive. Not Busy.</h2>
                  <p className="text-white mt-4">
                    peakSUITE drives every hour employees work toward growing
                    your company.
                  </p>
                </div>
              </div>
            </div>
          </section>*/}
      <section
        className="bg-indigo"
        style={{
          paddingTop: "5rem",
          paddingBottom: "5rem",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-10 mx-auto text-center text-white text-left-mobile">
              <h2 className="text-white">
                peakSUITE: Business Process Optimization That's ROI Positive
              </h2>
              <p>
                Automating your systems can result in 70% - 80% ROI in the first
                year alone (and over 150% in the years after).
              </p>
              <Link
                className="speak-to-btnx btn btn-primary btn-arrow mt-4"
                to="/contact/"
              >
                Speak to a Technology Consultant
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* <section>
        <div className="container">
          <div style={{ padding: "5rem 0" }}>
            <h4 className="text-center mb-lg-4 mb-md-4 mb-4">Coming Soon</h4>
            <div className="row justify-content-center">
              
              <div className="col-6 col-lg-2 col-md-6">
                <Link
                  to="/peaksuite/#"
                  style={{ pointerEvents: "none", curson: "default" }}
                >
                  <StaticImage
                    placeholder="blurred"
                    imgClassName="img-fluid"
                    src="../../../static/assets/productLogos/PS_apex-crm.png"
                    alt="peakSuite Apex CRM"
                    layout="constrained"
                    loading="lazy"
                  />
                </Link>
              </div>
              <div className="col-6 col-lg-2 col-md-6">
                <Link
                  to="/peaksuite/#"
                  style={{ pointerEvents: "none", curson: "default" }}
                >
                  <StaticImage
                    placeholder="blurred"
                    imgClassName="img-fluid"
                    src="../../../static/assets/productLogos/PS_apex-finance.png"
                    alt="peakSuite Apex Finance"
                    layout="constrained"
                    loading="lazy"
                  />
                </Link>
              </div>
              <div className="col-6 col-lg-2 col-md-6">
                <Link
                  to="/peaksuite/#"
                  style={{ pointerEvents: "none", curson: "default" }}
                >
                  <StaticImage
                    placeholder="blurred"
                    imgClassName="img-fluid"
                    src="../../../static/assets/productLogos/PS_apex-hr.png"
                    alt="peakSuite Apex HR"
                    layout="constrained"
                    loading="lazy"
                  />
                </Link>
              </div>
              <div className="col-6 col-lg-2 col-md-6">
                <Link
                  to="/peaksuite/#"
                  style={{ pointerEvents: "none", curson: "default" }}
                >
                  <StaticImage
                    placeholder="blurred"
                    imgClassName="img-fluid"
                    src="../../../static/assets/productLogos/PS_apex.png"
                    alt="peakSuite Apex"
                    layout="constrained"
                    loading="lazy"
                  />
                </Link>
              </div>
              <div className="col-6 col-lg-2 col-md-6">
                <Link
                  to="/peaksuite/#"
                  style={{ pointerEvents: "none", curson: "default" }}
                >
                  <StaticImage
                    placeholder="blurred"
                    imgClassName="img-fluid"
                    src="../../../static/assets/productLogos/PS_Revenue.png"
                    alt="peakSuite Revenue"
                    layout="constrained"
                    loading="lazy"
                  />
                </Link>
              </div>
              
            </div>
          </div>
        </div>
      </section> */}
      {/*}<section id="quotes" style={{ padding: "5rem 0" }}>
            <div className="container">
              <div className="col-lg-12 text-center">
                <h2>Looking for More Solutions? Explore Our Other Products:</h2>
                <a
                  className="btn btn-primary btn-arrow mr-lg-4 mr-md-4 mt-4"
                  href="/workato-solutions/"
                >
                  Workato Solutions
                </a>
                <a
                  className="btn btn-primary btn-arrow mt-4"
                  href="/quick-base-solutions/"
                >
                  Quickbase Solutions
                </a>
              </div>
            </div>
          </section> */}
    </div>
  </>
)

export default PeaksuiteSections
